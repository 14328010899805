import React from "react";
import TitleImageComponent from "../../components/image/TitleImageComponent";
import browserHistory from "../../helper/browserHistory";
import history from "../../helper/browserHistory";
import EditSimpleValueComponent from "../../components/moap-components/EditSimpleValueComponent";
import {inject} from "mobx-react";

import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";
import Breadcrumb from "semantic-ui-react/dist/commonjs/collections/Breadcrumb";
import Loadable from "react-loadable";
import LoaderComponent from "../../components/LoaderComponent";

const StifterPDF = Loadable({
    loader: () => import(/* webpackChunkName: 'StifterPDF' */ "./StifterPDF"),
    loading: () => LoaderComponent
});

const PAGE_LINK = "Stiferbrief";

@inject("stores")
class StifterBriefPage extends React.Component {


    constructor(props) {
        super(props);
        this.pdf_ref = React.createRef();
            this.state = {
                textStore: props.stores.textStore.texts,
                isLoading: true,
                stifter: <div>Laden...</div>
            }
    }

    componentDidMount() {
        this.props.stores.trackingStore.addVisit(PAGE_LINK);
        window.scrollTo(0, 0);
        history.listen(location => {
            this.updateHistory();
        });

        this.setState({...this.state, stifter: <StifterPDF/>})
    }

    updateHistory() {
        let error = false;

        if (window.location.hash.length > 0) {
            switch (window.location.hash) {
                case "#pdf":
                    if (this.pdf_ref.current != null) {
                        setTimeout(() => {
                            this.pdf_ref.current.scrollIntoView({behavior: 'smooth', block: 'start'})
                        }, 0);
                    } else {
                        error = true;
                        console.log(this.pdf_ref)
                    }
                    break;
                default:
                    error = true;
                    break;
            }
        } else {
            error = true;
        }
        if (error) {
            console.log("error");
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        }
    }


    render() {
        const Seperator = "https://www.buergerstiftung-kreis-rv.de/images/seperator.png";

        return (
            <Grid>
                <TitleImageComponent
                    textStore={this.state.textStore}
                    namespace={'stifter'}
                    imageLinkId={"stifter-page-title-image-id"}
                    headerId={"stifter-page-title-header-id"}
                    subheaderId={"stifter-page-subtitle-header-id"}
                    buttonTextId={"stifter-page-button-text-id"}
                    buttonLinkId={"stifter-page-button-link-id"}
                    small
                />
                <Grid.Row>
                    <Grid.Column width={2} only={'computer'}/>
                    <Grid.Column computer={14} tablet={16} mobile={16}>
                        <Breadcrumb className={"breadcrumb-container"}>
                            <Breadcrumb.Section className={"inactive-breadcrumb"} onClick={() => {
                                browserHistory.push("/");
                                this.setState({});
                            }}>
                                HOME</Breadcrumb.Section>
                            <Breadcrumb.Divider icon='right chevron'/>
                            <Breadcrumb.Section className={"active-breadcrumb"}>STIFTERBRIEF</Breadcrumb.Section>
                        </Breadcrumb>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered className={"page-row"}>
                    <Grid.Column computer={12} tablet={16} mobile={16} textAlign={"center"}>
                        <Image centered
                               src={Seperator}
                               size={"tiny"}
                        />
                        <h2>
                            <EditSimpleValueComponent textStore={this.state.textStore}
                                                                                id={"stifter-page-title-sector-1-id"}
                                                                                namespace={'stifter'}/>
                        </h2>
                        <p>
                           <EditSimpleValueComponent textStore={this.state.textStore}
                                                                                id={"stifter-page-title-sector-2-id"}
                                                                                namespace={'stifter'}/>
                        </p>
                    </Grid.Column>
                </Grid.Row>
                <div className={"anchor"} ref={this.pdf_ref}/>
                <Grid.Row centered>
                    <Grid.Column computer={12} tablet={16} mobile={16} textAlign={"center"}>
                        {this.state.stifter}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

export default StifterBriefPage